.titlepremiumbody {
    min-width: 100%;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .form_res {
        width: 400px;
        height: 100%;
        padding: 10px;

        .fieldset2 {
            // padding-top: 25px;

            .s_field {
                margin-top: 20px;

                .inputlabel {
                    font-weight: bold;
                }
            }
        }

        .radi_f {
            display: flex;
            align-items: center;

            .check_label {
                margin-left: 15px !important;
            }
        }

        .radio_field {
            // width: 100%;
            margin-top: auto !important;
            height: 20px;
        }

        .checkfields {
            padding: 40px 0 30px 25px;
            border: solid 1px rgb(179, 179, 179);
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            border-radius: 5px;
        }

        .legend_title {
            margin-top: -60px;
            padding: 10px 10px 10px 25px;
            background-color: #ffff;
            width: 60%;
            font-family: 'Heebo-Bold';
        }

        .inp_field {
            width: 100%;
            border: none;
            border-width: 1px;
            border: solid 1px rgb(179, 179, 179);
            border-radius: 5px;
            height: 40px;
        }

        .inp_field:focus {
            outline: none;
        }

    }

    .title_result_body {
        margin-top: 50px;
        width: 380px;
        height: 300px;
        box-shadow: 0px 0px 20px rgba(9, 3, 0, 0.1);
        border: 1px solid #efecec;
        display: flex;
        justify-content: center;
        align-items: center;

        .resultcontainer {
            // display: flex;
            text-align: center;
            font-size: x-large;
            font-family: Montserrat-Regular !important;
            height: 90%;
            display: flex;
            justify-content: space-evenly;
            /* align-items: center; */
            flex-direction: column;

            .resultcont_sub {

                .fontto18{
                    font-size: 18px !important;
                }

                .res_val {
                    font-family: Montserrat-SemiBold !important;
                }

                .result_list1 {
                    width: 250px;
                    height: auto;
                    position: absolute;
                    background-color: #ffff;
                    box-shadow: 0px 0px 20px rgba(9, 3, 0, 0.1);
                }

                .listnone {
                    display: none;
                }

                .dropdownlist {
                    margin-top: 10px;
                    font-size: medium;
                    cursor: pointer;
                }

                .downarr_padd {
                    padding-left: 10px;
                }
            }
        }
    }
}

.rollcosbox {
    width: 22px;
    height: 22px;
    border: 1px solid #b4b6bb;
    margin-right: 8px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
}


.form-control {
    // height: 40px !important;
}

.county-name {
    letter-spacing: 0.5px !important;
}
.iframe_title_premium {
    display: flex;
    justify-content: center;
    // padding-left: 21%;
    align-items: center;
    flex-direction: column;
}
.title_premium_logo {
    // padding-left: 32%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.title_label {
    font-size: 16px;
    font-family: Montserrat-Regular;
}
.title_value {
    font-size: 18px;
    font-family: Montserrat-SemiBold;
    padding-left: 5px;
}
.title_center {
    display: flex;
    padding: 1% 0;
    justify-content: center;
}
// @media (min-width: 280px) and (max-width: 991px) {

//     .form_res {
//         width: 100% !important;
//     }
//     .legend_title{
//         width: fit-content !important;
//     }
//     .title_result_body {
//         width: 60% !important;
//     }
// }


@media (min-width: 768px){
    .result_response_form{
        height: 418px;
        // justify-content: center;
        display: flex;
        // align-items: flex-start;
    }
    .result_response_result{
        height: 768px;
        justify-content: center;
        display: flex;
        align-items: flex-start;
    }

    .iframe_logo_link1{
        width: 40%;
    }
}
@media (min-width: 1821px){
    .result_response_form, .result_response_result{
        justify-content: initial !important;
    }
}
@media (min-width: 1200px){
    .refi_iframe_logo_link ,.iframe_logo_link{
        width: 190px;
    }  
    .iframe_logo_div_refi {
        margin: 48% 0 0 5%;
    }
    .iframe_seller_result ,.iframe_logo_div{
        margin: 48% 0 0 20%;
    }
}
@media (min-width: 1366px){
    .iframe_logo_link1{
        // width: 25%;
        width: 170px;
    }
    .iframe_logo_div_refi ,.iframe_seller_result {
        margin: 48% 0 0 30%;
    }
    .refi_iframe_logo_link{
        width: 190px;
    }
    .result_response_form{
        justify-content: center;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .iframe_logo_link1{
        width: 100%;
    }
    .iframe_logo_div_refi{
        width: 200px;
    }
    .align_form{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media (min-width: 280px) and (max-width: 768px) {
    .titlepremiumbody{
        flex-direction: column;
    }
    .iframe_logo_link1{
        width: 100%;
    }
    .iframe_logo_div_refi{
        margin: 0 !important;
        width: 300px !important;
    }
    .mbl_res{
        display: flex;
        justify-content: center;
    }
    .mbl_form_res{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .iframe_result_res{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .iframe_form2{
        width: 90%;
    }
    .setIcon_width{
        width: 200px;
    }
}

@media (min-width:524px) and (max-width: 768px) {
    .iframe_logo_link1{
        width: 60%;
    }
    .iframe_result_res{
        display: flex;
        // justify-content: space-between;
        // align-items: center;
        width: 100%;
    }
    .iframe_form2{
        width: 100%;
    }
    .align_form{
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}